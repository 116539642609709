'use strict';

window.ranger = window.ranger || {};

/**
 * Handles the single post UI.
 */
ranger.Parse = class {
  /**
   * Initializes the parse hand.
   * @constructor
   */
  constructor() {

    // Firebase SDK.
    this.database = firebase.database();
    this.auth = firebase.auth();

    $(document).ready(() => {

    });
  }


  calcPosition(n, btnPos) {
    var posDict = {
      2: ['BB', 'SB'],
      3: ['SB', 'BB', 'BTN'],
      4: ['SB', 'BB', 'UTG', 'BTN'],
      5: ['SB', 'BB', 'UTG', 'CO', 'BTN'],
      6: ['SB', 'BB', 'UTG', 'HJ', 'CO', 'BTN'],
      7: ['SB', 'BB', 'UTG', 'UTG1', 'HJ', 'CO', 'BTN'],
      8: ['SB', 'BB', 'UTG', 'UTG1', 'UTG2', 'HJ', 'CO', 'BTN'],
      9: ['SB', 'BB', 'UTG', 'UTG1', 'UTG2', 'UTG3', 'HJ', 'CO', 'BTN'],
  		10: ['SB', 'BB', 'UTG', 'UTG1', 'UTG2', 'UTG3', 'MP', 'HJ', 'CO', 'BTN'],
    };
    var posMap = posDict[n];

    if (!posMap)  {
      console.error('calcPosition: not able to find position map for count: ' + n)
    }

    if (btnPos > n) {
      console.error('calcPosition: btnPos should be less than n. (n: ' + n + ', btnPos: ' + btnPos + ')')
    }
    console.log(posMap);
    return [].concat(
      posMap.slice(n - btnPos),
      posMap.slice(0, n - btnPos)
    )
  };

  parseHand(hh) {
    var parser;
    let hand = {}
    let handSteps = [];
    window.build = {}
    build.players = [];
    build.output = [];
    build.preflop = [];
    build.flop = [];
    build.turn = [];
    build.river = [];
    build.flopCard = [];
    build.turnCard = [];
    build.riverCard = [];
    build.me = [];
    build.action = [];
    build.blind;
    build.btnPos;
    build.showdown = [];
    build.winner = [];
    build.gameStatus = 'PREFLOP';
    if (hh[1].indexOf('NL Texas Hold\'em') !== -1) {
      parser = 'party';
    } else {
      parser = 'pokerstars';
    }
    hh.forEach(function(lines) {
      if (parser === 'party') {
        ranger.parse.checkParty(lines);
      } else if (parser == 'pokerstars') {
        ranger.parse.checkStars(lines);
      }
  });

  hand.settings = {}
  hand.seat = {};
  hand.seat.btnPos = build.btnPos;
  hand.seat.total = build.players.length;
  hand.players = build.players;
  hand.settings.blind = build.blind;
  hand.settings.ante = build.ante;
  hand.actions = {}
  hand.actions.preflop = build.preflop;
  hand.actions.flop = build.flop;
  hand.actions.turn = build.turn;
  hand.actions.river = build.river;
  hand.cards = {}
  hand.cards.flop = build.flopCard;
  hand.cards.turn = build.turnCard;
  hand.cards.river = build.riverCard;
  hand.result = {}
  hand.result.showdown = build.showdown;
  hand.result.winner = build.winner;
  hand.money = {};
  hand.money.preflop = 0;
  hand.money.flop = 0;
  hand.money.turn = 0;
  hand.money.river = 0;
  hand.money.total = 0;
  hand.me = {};
  hand.stages = {};
  hand.me = build.me;

  var findIndex = function (predicate, list) {
    console.log(list);
    for (var i = 0, len = list.length; i < len; i++) {
      console.log(list);
      if (predicate(list[i], i))  return i
    }

    return -1
  };
  var utils ={extend, findIndex};
  var extend = function () {
    var args = [].slice.apply(arguments);
    var len  = args.length;

    if (len <= 0)   return {}
    if (len === 1)  return args[0]

    var head = args[0];
    var rest = args.slice(1);

    return rest.reduce(function (prev, cur) {
      for (var i = 0, keys = Object.keys(cur), len = keys.length; i < len; i++) {
        prev[keys[i]] = cur[keys[i]];
      }

      return prev
    }, head)
  };

  var btnIndex      = utils.findIndex(function (player) {
    return player.pos === hand.seat.btnPos
  }, hand.players);
  console.log(btnIndex);

  var positionCalc = ranger.parse.calcPosition(hand.seat.total, btnIndex+1);
  var updatedPlayers = hand.players.map(function (player, i) {
    return extend(player, { seat: positionCalc[i] })
  });

  function findHero(username) {
    console.log(username.username + ' - ' + hand.me[0].username);
    return username.username === hand.me[0].username
  }

  hand.players.findIndex(findHero);
  var hero = hand.players.findIndex(findHero);
  var heroPos = hand.players[hero].pos;

  var cardLeft = hand.me[0].cards[0].substr(0,1);
  var cardRight = hand.me[0].cards[1].substr(0,1);
  // Generate Steps
  hand.actions.preflop.forEach(function(action, index, array) {
    if (action.total === undefined || action.total === '') {
      action.total = '0';
    }
    if (action.type === 'POST SB') {
      hand.stages.start = handSteps.length;
    }
    handSteps.push(action);
    if (index+1 === array.length) {

    }
    hand.money.preflop = hand.money.preflop + parseInt(action.total);

  });
  handSteps.push({type: "DEAL FLOP"});
  hand.stages.flop = handSteps.length;
  hand.actions.flop.forEach(function(action, index, array) {
    if (action.total === undefined || action.total === '') {
      action.total = '0';
    }
    //$('.' + action.username).append($('<span/>', {class: 'actions'}).html(action.type + ' ' + action.total || '') )
    handSteps.push(action);
    if (index+1 === array.length) {

    }
    console.log(action.total);
    hand.money.flop = hand.money.flop + parseInt(action.total);
  });
  handSteps.push({type: "DEAL TURN"});
  hand.stages.turn = handSteps.length;
  hand.actions.turn.forEach(function(action, index, array) {
    if (action.total === undefined || action.total === '') {
      action.total = '0';
    }
    //$('.' + action.username).append($('<span/>', {class: 'actions'}).html(action.type + ' ' + action.total || '') )
    handSteps.push(action);
    if (index+1 === array.length) {

    }
    hand.money.turn = hand.money.turn + parseInt(action.total);
  });
  handSteps.push({type: "DEAL RIVER"});
  hand.stages.river = handSteps.length;
  hand.actions.river.forEach(function(action, index, array) {
    if (action.total === undefined || action.total === '') {
      action.total = '0';
    }
    handSteps.push(action);
    if (index+1 === array.length) {

    }
    hand.money.river = hand.money.river + parseInt(action.total);
  });
  handSteps.push({type: "SHOWDOWN"});

  return hand;
}

  checkStars(lines) {
       //Push Players Array
       var seatCheck = /^Seat (\d+): (.+?) \((\d+\.?\d+?) in chips(, (\$\d.*?) bounty)?\)/;
       var seatCheckOld = /^Seat (\d+): (.+?) \(.(\d+\.?\d+?) in chips(, (\$\d.*?) bounty)?\)/
       if (seatCheck.test(lines)) {
         var match = lines.match(seatCheck);
         if (match[5] == undefined) {
           match[5] = 0;
         }

         build.players.push({
           pos: parseInt(match[1]),
           username: match[2].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           chips: parseFloat(match[3]),
           bounty: match[5],
           actions: []
         })
         $('.s'+match[1]+' > .card-holder').addClass('empty');
       } else if (seatCheckOld.test(lines)) {
         var match = lines.match(seatCheckOld);
         if (match[5] == undefined) {
           match[5] = 0;
         }

         build.players.push({
           pos: parseInt(match[1]),
           username: match[2].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           chips: parseFloat(match[3]),
           bounty: match[5],
           actions: []
         })
         $('.s'+match[1]+' > .card-holder').addClass('empty');
       }

       //Blinds
       var regBlind    = /\(\$?(\d*\.?\d*)\/\$?(\d*\.?\d*)(\s.*?)?\)/;
       if (regBlind.test(lines)) {
         var matchBlind = lines.match(regBlind);
         build.blind = {
           small: parseFloat(matchBlind[1]),
           big: parseFloat(matchBlind[2])
         }
       }

       //Button Position
       var regSeat = /Table '.*?' (\d+).*? Seat #(\d+) is the button/
       if (regSeat.test(lines)) {
         var matchSeat = lines.match(regSeat)
         console.log(matchSeat[2]);
         build.btnPos = parseInt(matchSeat[2])
       }

       //Preflop Actions
       //Ante
       var regAnte = /^(.*?): posts the ante \$?(\d*\.?\d*)/
       // var reg = /^(.*?): posts the ante (\d+)/;
       if (regAnte.test(lines)) {
         var m = lines.match(regAnte)
        build.ante = parseFloat(m[2]);
         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'POST ANTE',
           total: parseFloat(m[2])
         };
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }

       //Small Blind
       var regSB = /^(.*?): posts small blind \$?(\d*\.?\d*)/
       if (regSB.test(lines)) {
         var m   = lines.match(regSB)

         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'POST SB',
           total: parseFloat(m[2])
         };
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }

       //Big Blind
       var regBB = /^(.*?): posts big blind \$?(\d*\.?\d*)/
       if(regBB.test(lines)) {
         var m   = lines.match(regBB)

         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'POST BB',
           total: parseFloat(m[2])
         };
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }

       //Folds
       var regFold = /^(.*?): folds/
       if(regFold.test(lines)) {
         var m   = lines.match(regFold)

         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'FOLD'
         }
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }

       //CHECKS
       var regCheck = /^(.*?): checks/
       if(regCheck.test(lines)) {
         var m   = lines.match(regCheck)

         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'CHECK',
           total: ''
         }
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }

       //BETS
       var regBet = /^(.*?): bets \$?(\d*\.?\d*)(.*?(all-in))?/
       if(regBet.test(lines)){
         var ma   = lines.match(regBet)
           build.action = {
             username: ma[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
             type: 'BETS',
             total: parseFloat(ma[2]),
             base: 0,
             isAllIn: !!ma[4]
           }
           switch(build.gameStatus) {
             case 'FLOP':
               build.flop.push(build.action);
               break;
             case 'TURN':
               build.turn.push(build.action);
               break;
             case 'RIVER':
               build.river.push(build.action);
               break;
             case 'PREFLOP':
               build.preflop.push(build.action);
               break;
             default:
               break;
           }
         }

         var regRaise = /^(.*?): raises \$?(\d*\.?\d*) to \$?(\d*\.?\d*)(.*?(all-in))?/
         if(regRaise.test(lines)) {
           var mb   = lines.match(regRaise)
           build.action = {
             username: mb[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
             type: 'RAISES',
             total: parseFloat(mb[3]),
             base: parseFloat(mb[3]) - parseFloat(mb[2]),
             extra: parseFloat(mb[2]),
             isAllIn: !!mb[5]
           }
           switch(build.gameStatus) {
             case 'FLOP':
               build.flop.push(build.action);
               break;
             case 'TURN':
               build.turn.push(build.action);
               break;
             case 'RIVER':
               build.river.push(build.action);
               break;
             case 'PREFLOP':
               build.preflop.push(build.action);
               break;
             default:
               break;
           }
         }

       //Calls
       var regCalls = /^(.*?): calls \$?(\d*\.?\d*)(.*?(all-in))?/
       if(regCalls.test(lines)) {
         var m   = lines.match(regCalls)
         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'CALLS',
           total: parseFloat(m[2]),
           isAllIn: !!m[4]
         }
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }

       }

       //Hero Cards
       var regHero = /^Dealt to (.*?) \[([^\]]+)\]/
       if(regHero.test(lines)) {
         var m   = lines.match(regHero)
         build.me.push({
           cards: m[2].split(/\s+/),
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, '')
         })
       }

       //Showdown
       var regShow = /^(.*?): shows \[\s*([^\]]+?)\s*\] \((.*?)\)/
       if(regShow.test(lines)) {
         var m = lines.match(regShow)
         build.showdown.push({
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           cards: m[2].split(/\s+/),
           result: m[3]
         })
       }

       //Deal Flop
       var regFlop = /^\*\*\* FLOP \*\*\*.*?\[([^\]]+)\](\r|\n)?$/
       if(regFlop.test(lines)) {
         build.gameStatus = 'FLOP';
         var m = lines.match(regFlop)
         build.flopCard.push({
           cards: m[1].split(/\s+/)
         })
       }
       //Deal Turn
       var regTurn = /^\*\*\* TURN \*\*\*.*?\[([^\]]+)\](\r|\n)?$/
       if(regTurn.test(lines)) {
         build.gameStatus = 'TURN';
         var m = lines.match(regTurn)
         build.turnCard.push({
           cards: m[1].split(/\s+/)
         })
       }
       //Deal River
       var regRiver = /^\*\*\* RIVER \*\*\*.*?\[([^\]]+)\](\r|\n)?$/
       if(regRiver.test(lines)) {
         build.gameStatus = 'RIVER';
         var m = lines.match(regRiver)

         build.riverCard.push({
           cards: m[1].split(/\s+/)
         });
       }

       //Winner
       var regCollect  = /^(.*?) collected \s*([\d,]+)/
       if(regCollect.test(lines)) {
         var m = lines.match(regCollect)
         build.winner.push({
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           total: m[2]
         })
       }
   }

   checkParty(lines) {

     var numberRegText = "(?:\\$)?(\\d+|[\\d.]+[kKmM]|[\\d,]+|[\\d.]+)(?:\\s*USD)?"
     //Push Players Array
     var seatCheck = new RegExp(`^Seat (\\d+): (.+?) \\(\\s*${numberRegText}\\s*\\)`)
     if (seatCheck.test(lines)) {
       var match = lines.match(seatCheck);

       build.players.push({
         pos: parseInt(match[1]),
         username: match[2].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
         chips: int(match[3]),
         bounty: 0,
         actions: []
       })
       $('.s'+match[1]+' > .card-holder').addClass('empty');
     }

       //Blinds
       var regBlindParty = new RegExp(`Blinds(?:-Antes)?\\(${numberRegText}\\/${numberRegText}\\s*(?:-\\s*${numberRegText})?\\)`)
       if (regBlindParty.test(lines)) {
         var matchBlind = lines.match(regBlindParty);
         build.blind = {
           small: int(matchBlind[1]),
           big: int(matchBlind[2])
         }
       }

       //Blinds Cash
       var regBlindPartyCash = new RegExp(`Blinds(?:-Antes)?\\(${numberRegText}\\/${numberRegText}\\s*(?:-\\s*${numberRegText})?\\)`)
       if (regBlindPartyCash.test(lines)) {
         var matchBlind = lines.match(regBlindPartyCash);
         build.blind = {
           small: int(matchBlind[1]),
           big: int(matchBlind[2])
         }
       }

       //Button Position
       var regSeat = /Seat (\d+) is the button/
       if (regSeat.test(lines)) {
         var matchSeat = lines.match(regSeat)
         build.btnPos = parseInt(matchSeat[1])
       }

       //Preflop Actions
       //Ante
       var regAnte = new RegExp(`^(.*?) posts ante \\[\\s*${numberRegText}\\s*\\]`)
       if (regAnte.test(lines)) {
         var m = lines.match(regAnte)

         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'POST ANTE',
           total: int(m[2])
         };
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }

       //Small Blind
       var regSB = new RegExp(`^(.*?) posts small blind \\[\\s*${numberRegText}\\s*\\]`)
       if (regSB.test(lines)) {
         var m   = lines.match(regSB)

         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'POST SB',
           total: int(m[2])
         };
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }

       //Big Blind
       var regBB = new RegExp(`^(.*?) posts big blind \\[\\s*${numberRegText}\\s*\\]`)
       if(regBB.test(lines)) {
         var m = lines.match(regBB)

         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'POST BB',
           total: int(m[2])
         };
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }

       //Folds
       var regFold = /^(.*?) folds/
       if(regFold.test(lines)) {
         var m   = lines.match(regFold)

         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'FOLD'
         }
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }
       //CHECKS
       var regCheck = /^(.*?) checks/
       if(regCheck.test(lines)) {
         var m   = lines.match(regCheck)

         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'CHECK',
           total: ''
         }
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }
       //BETS
       var reg3 = new RegExp(`^(.*?) is all-In\\s+\\[\\s*${numberRegText}\\s*\\]`)
       if(reg3.test(lines)) {
         var ma = lines.match(reg3);
         build.action = {
           username: ma[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'BETS',
           total: int(ma[2]),
           base: 0,
           isAllIn: false
         }
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }
       }
       var regBet = new RegExp(`^(.*?) bets \\[\\s*${numberRegText}\\s*\\]`)
       if(regBet.test(lines)){
         var ma   = lines.match(regBet)
           build.action = {
             username: ma[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
             type: 'BETS',
             total: int(ma[2]),
             base: 0,
             isAllIn: false
           }
           switch(build.gameStatus) {
             case 'FLOP':
               build.flop.push(build.action);
               break;
             case 'TURN':
               build.turn.push(build.action);
               break;
             case 'RIVER':
               build.river.push(build.action);
               break;
             case 'PREFLOP':
               build.preflop.push(build.action);
               break;
             default:
               break;
           }
         }
         var regRaise = new RegExp(`^(.*?) raises \\[\\s*${numberRegText}\\s*\\]`)
         if(regRaise.test(lines)) {
           var mb   = lines.match(regRaise)
           build.action = {
             username: mb[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
             type: 'RAISES',
             total: int(mb[2]),
           }
           switch(build.gameStatus) {
             case 'FLOP':
               build.flop.push(build.action);
               break;
             case 'TURN':
               build.turn.push(build.action);
               break;
             case 'RIVER':
               build.river.push(build.action);
               break;
             case 'PREFLOP':
               build.preflop.push(build.action);
               break;
             default:
               break;
           }
         }

       //Calls
       var regCalls = new RegExp(`^(.*?) calls \\[\\s*${numberRegText}\\s*\\]`)
       if(regCalls.test(lines)) {
         var m   = lines.match(regCalls)
         build.action = {
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           type: 'CALLS',
           total: int(m[2]),
           isAllIn: false
         }
         switch(build.gameStatus) {
           case 'FLOP':
             build.flop.push(build.action);
             break;
           case 'TURN':
             build.turn.push(build.action);
             break;
           case 'RIVER':
             build.river.push(build.action);
             break;
           case 'PREFLOP':
             build.preflop.push(build.action);
             break;
           default:
             break;
         }

       }

       //Hero Cards
       var regHero = /^Dealt to (.*?) \[\s*([^\]]+?)\s*\]/
       if(regHero.test(lines)) {
         var m   = lines.match(regHero)
         build.me.push({
           cards: m[2].split(/\s+/),
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, '')
         })
       }
       //Showdown
       var regShow = /^(.*?) shows\s*\[\s*([^\]]+?)\s*\](.*)\./
       if(regShow.test(lines)) {
         var m = lines.match(regShow)
         build.showdown.push({
           [m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, '')]: {cards: m[2].split(/\s+/), result: m[3]}
         })
       }

       //Deal Flop
       var regFlop = /^(?:\*\*)?\s*Dealing Flop\s*(?:\*\*)?.*?\[\s*([^\]]+?)\s*\]/
       if(regFlop.test(lines)) {
         build.gameStatus = 'FLOP';
         var m = lines.match(regFlop)
         build.flopCard.push({
           cards: m[1].split(/\s+/)
         })
       }
       //Deal Turn
       var regTurn = /^(?:\*\*)?\s*Dealing Turn\s*(?:\*\*)?.*?\[\s*([^\]]+?)\s*\]/
       if(regTurn.test(lines)) {
         build.gameStatus = 'TURN';
         var m = lines.match(regTurn)
         build.turnCard.push({
           cards: m[1].split(/\s+/)
         })
       }
       //Deal River
       var regRiver = /^(?:\*\*)?\s*Dealing River\s*(?:\*\*)?.*?\[\s*([^\]]+?)\s*\]/
       if(regRiver.test(lines)) {
         build.gameStatus = 'RIVER';
         var m = lines.match(regRiver)

         build.riverCard.push({
           cards: m[1].split(/\s+/)
         });
       }
       //Winner
       var regCollect  = new RegExp(`^(.*?) wins \\s*${numberRegText}\\s*[^\\d]+?(with (.+))?$`)
       if(regCollect.test(lines)) {
         var m = lines.match(regCollect)
         build.winner.push({
           username: m[1].replace(/[^a-zA-Z0-9 !?]+/g, '').replace(/\s/g, ''),
           total: int(m[2])
         })
       }
   }
}
ranger.parse = new ranger.Parse();
